import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import HollMatches from "./HollMatches";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FullScreenLoader from "../Components/FullScreenLoader"; // Loader component

export default function SelectHoll({ setLoadingState }) {
  const [venues, setVenues] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true); // Start as true to show loader initially

  const initialVenue = searchParams.get("venue") || "";
  const initialDate = searchParams.get("date") ? new Date(searchParams.get("date")) : new Date();

  const [selectedVenue, setSelectedVenue] = useState(initialVenue);
  const [selectedDate, setSelectedDate] = useState(initialDate);

  useEffect(() => {
    const fetchVenues = async () => {
      setLoading(true);
      setLoadingState(true);

      try {
        const response = await fetch(
          "https://us-central1-hsf-production-505a6.cloudfunctions.net/webApi/venues"
        );
        const data = await response.json();
        const sortedVenues = data.sort((a, b) => a.name.localeCompare(b.name));

        setVenues(sortedVenues);
      } catch (error) {
        console.error("Error fetching venues:", error);
      } finally {
        setTimeout(() => {
          setLoading(false); // Unmount loader after a short delay to ensure UI updates
          setLoadingState(false);
        }, 300); // Small delay ensures data is rendered first
      }
    };

    fetchVenues();
  }, []); // Runs only on mount

  const handleVenueChange = (event) => {
    const venue = event.target.value;
    setSelectedVenue(venue);
    setSearchParams({ venue, date: selectedDate ? selectedDate.toISOString().split("T")[0] : "" });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSearchParams({ venue: selectedVenue, date: date ? date.toISOString().split("T")[0] : "" });
  };

  return (
    <>
      {loading && <FullScreenLoader />} {/* Loader only unmounts after data is displayed */}

      <section className="select-holl">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3>Finn dystir í hallum</h3>
            </div>
            <div className="col-12 col-md-6">
              <div className="head">Í Høll</div>
              <div className="relative">
                <select className="hollselct" onChange={handleVenueChange} value={selectedVenue}>
                  <option key="velholl" value="">
                    Vel høll
                  </option>
                  {venues.map((venue) => (
                    <option key={venue.id.trim()} value={venue.id.trim()}>
                      {venue.name}
                    </option>
                  ))}
                </select>
                <div className="select-arrow">
                  <img src="/caret-down.png" alt="" />
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="head">Frá degi</div>
              <div className="relative">
                <DatePicker
                  className="dateselect"
                  selected={selectedDate}
                  onChange={handleDateChange}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Vel dag"
                />
                <img className="calicon" src="/calendar.svg" alt="" />
              </div>
            </div>

            {selectedVenue !== "" && selectedDate && (
              <HollMatches
                selectedVenue={selectedVenue}
                selectedDate={selectedDate.toISOString().split("T")[0]}
                setLoadingState={setLoadingState}
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
}
