import { useState, useEffect } from "react";
import ShowDivisionDropdown from "./ShowDivisionDropdown";
import FullScreenLoader from "../Components/FullScreenLoader"; // Make sure the FullScreenLoader component is imported

export default function SelectDivision({
  selectedCompetitionData,
  competitionName,
  setLoadingState,
}) {
  const [divisionData, setDivisionData] = useState([]);
  const [activeDivision, setActiveDivision] = useState(null);
  const [ageGroup, setAgeGroup] = useState("");
  const [loading, setLoading] = useState(true); // Track loading state

  useEffect(() => {
    // Load the stored division and age group from localStorage if available
    const storedActiveDivision = localStorage.getItem("activeDivision");
    const storedAgeGroup = localStorage.getItem("ageGroup");

    // If localStorage contains valid values, set them as active division and age group
    if (storedActiveDivision) {
      setActiveDivision(storedActiveDivision);
    }
    if (storedAgeGroup) {
      setAgeGroup(storedAgeGroup);
    }

    // Fetch division data
    const fetchDivisionsData = async () => {
      try {
        setLoading(true); // Set loading state to true when fetching starts
        const response = await fetch(
          `https://us-central1-hsf-production-505a6.cloudfunctions.net/webApi/types/${selectedCompetitionData}/competitions`
        );
        const jsonData = await response.json();

        // Sort the data by the 'order' property
        jsonData.sort((a, b) => a.order - b.order);

        setDivisionData(jsonData);
      } catch (error) {
        console.error("Error fetching divisions data:", error);
      } finally {
        setLoading(false); // Set loading state to false when fetching finishes
      }
    };

    if (selectedCompetitionData) {
      fetchDivisionsData();
    }
  }, [selectedCompetitionData]);

  const handleClick = (id, value, age) => {
    const clickedElement = document.getElementById(id);

    // If the clicked element already has the 'active' class
    if (clickedElement.classList.contains("active")) {
      // Remove 'active' class from clicked element
      clickedElement.classList.remove("active");
      // Set the active division state to null
      setActiveDivision(null);
      setAgeGroup("");
      // Clear localStorage when the active division is deselected
      localStorage.removeItem("activeDivision");
      localStorage.removeItem("ageGroup");
    } else {
      // Remove 'active' class from all divisions
      document.querySelectorAll(".name").forEach((element) => {
        element.classList.remove("active");
      });
      // Add 'active' class to clicked element
      clickedElement.classList.add("active");
      // Set the active division state to the clicked division ID
      setActiveDivision(id);
      setAgeGroup(age);
      // Store the active division and age group in localStorage
      localStorage.setItem("activeDivision", id);
      localStorage.setItem("ageGroup", age);
    }
  };

  return (
    <>
      {loading && <FullScreenLoader />} {/* Show FullScreenLoader while fetching */}
      
      <section className="select-division">
        <div className="container">
          <div className="row justify-content-center division-grid-wrapper">
            {divisionData.map((division, index) => (
              <div
                className={`division colnumber-${(index % 4) + 1} col-md-3 col-6`}
                data-grouprel={division.id}
                key={division.id}
              >
                <div
                  id={division.id}
                  onClick={() => handleClick(division.id, division.name, division.ageGroup.name)}
                  value={division.id}
                  className={`name ${activeDivision === division.id ? "active" : ""}`}
                >
                  {division.name}
                </div>

                {activeDivision === division.id && (
                  <ShowDivisionDropdown
                    activeDivision={activeDivision}
                    competitionName={competitionName}
                    ageGroup={ageGroup}
                    setLoadingState={setLoadingState}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
