import HeaderBar from "../Components/HeaderBar";
import Footer from "../Components/Footer";
import DystarYvirlit from "../Components/DystarYvirlit";
import PointsTable from "../Components/PointsTable";
import FullScreenLoader from "../Components/FullScreenLoader";
import { useParams, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Kolofon from "../Components/Kolofon";

export default function DystirOgStigatalva() {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [scheduleData, setScheduleData] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [cupStatus, setCupStatus] = useState(true);
  const [showTable, setShowTable] = useState(false);
  const [loading, setLoading] = useState(true);

  const setLoadingState = (value) => {
    setLoading(value);
  };

  const currentTab = searchParams.get("tab") || "dystir"; // Default to "dystir"

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingState(true);
        
        const response = await fetch(
          `https://us-central1-hsf-production-505a6.cloudfunctions.net/webApi/groups/${params.kappingId}/schedule`
        );
        const jsonData = await response.json();
        setScheduleData(jsonData.schedule);
        setGroupName(jsonData.group.name);
        setCupStatus(jsonData.group.cup);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoadingState(false);
      }
    };

    fetchData();
  }, [params.kappingId, currentTab]); // Re-fetch when tab or competition ID changes

  const handleTabChange = (tab) => {
    setSearchParams({ tab }); // Update URL with selected tab
  };

  return (
    <>
      <HeaderBar kappingSlag={params.kappingSlag} showBack={true} groupName={groupName} />
      <section className="matchesStanding-select">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <ul>
                <li
                  className={currentTab === "dystir" ? "tabselect active" : "tabselect"}
                  onClick={() => handleTabChange("dystir")}
                >
                  Dystir
                </li>
                {!cupStatus && (
                  <li
                    className={currentTab === "stodan" ? "tabselect active" : "tabselect"}
                    onClick={() => handleTabChange("stodan")}
                  >
                    Støðan
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </section>

      {currentTab === "dystir" && <DystarYvirlit scheduleData={scheduleData} groupName={groupName} />}
      {currentTab === "stodan" && (
        <PointsTable setLoadingState={setLoadingState} competitionId={params.kappingId} />
      )}
      <Footer />
      <Kolofon />
      {loading && <FullScreenLoader />}
    </>
  );
}
