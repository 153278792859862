import { useState, useEffect, useRef } from "react";
import SelectDivision from "./SelectDivision";

export default function CompetitionSelect({ seasonId, setLoadingState }) {
  const [typesData, setTypesData] = useState([]);
  const [competitionName, setCompetitionName] = useState("Landskappingin");
  const [activeCompetitionTypeId, setActiveCompetitionTypeId] = useState(null);
  const listRef = useRef(null);
  const [touchStartX, setTouchStartX] = useState(null);
  const [touchEndX, setTouchEndX] = useState(null);

  useEffect(() => {
    // Load activeCompetitionTypeId and competitionName from localStorage if available
    const storedActiveCompetitionTypeId = localStorage.getItem("activeCompetitionTypeId");
    const storedCompetitionName = localStorage.getItem("competitionName");

    const fetchTypesData = async () => {
      try {
       // setLoadingState(true);
        const response = await fetch(
          `https://us-central1-hsf-production-505a6.cloudfunctions.net/webApi/seasons/${seasonId}/Types`
        );
        const jsonData = await response.json();
//console.log(jsonData);
        // Filter items with object.name of either 'Landskappingin' or 'Steypakapping'
        const filteredData = jsonData.filter(
          (type) =>
            type.name === "Landskappingin" ||
            type.name === "Steypakappingin" ||
            type.name === "Lionsbikarið"
        );

        // Sort the filtered items
        const sortedData = filteredData.sort((a, b) => {
          if (a.name === "Landskappingin") return -1;
          if (b.name === "Landskappingin") return 1;
          if (a.name === "Steypakappingin" && b.name !== "Landskappingin") return -1;
          if (b.name === "Steypakappingin" && a.name !== "Landskappingin") return 1;
          if (a.name === "Lionsbikarið") return 1;
          if (b.name === "Lionsbikarið") return -1;
          return 0;
        });

        setTypesData(sortedData);

        // Check localStorage for valid stored values, otherwise use the first item
        let selectedTypeId = storedActiveCompetitionTypeId;
        let selectedName = storedCompetitionName;

        // If localStorage does not match available options, fall back to the first item
        if (!selectedTypeId || !sortedData.some(type => type.id === selectedTypeId)) {
          selectedTypeId = sortedData[0]?.id;  // Set the first item if no valid match
          selectedName = sortedData[0]?.name;  // Set the first name if no valid match
        }

        setActiveCompetitionTypeId(selectedTypeId);
        setCompetitionName(selectedName);

      } catch (error) {
        console.error("Error fetching competition types data:", error);
      } finally {
        setLoadingState(false);
      }
    };

    if (seasonId) {
      fetchTypesData();
    }
  }, [seasonId, setLoadingState]);

  // Save the competition type and name to localStorage whenever they change
  useEffect(() => {
    if (activeCompetitionTypeId !== null) {
      localStorage.setItem("activeCompetitionTypeId", activeCompetitionTypeId);
    }
    if (competitionName) {
      localStorage.setItem("competitionName", competitionName);
    }
  }, [activeCompetitionTypeId, competitionName]);

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEndX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    handleSwipe();
    setTouchStartX(null);
    setTouchEndX(null);
  };

  const handleSwipe = () => {
    const deltaX = touchEndX - touchStartX;
    const threshold = 50; // Adjust swipe sensitivity as needed

    if (Math.abs(deltaX) > threshold) {
      if (deltaX > 0) {
        handlePreviousItem(); // Handle swipe right
      } else {
        handleNextItem(); // Handle swipe left
      }
    }
  };

  const handlePreviousItem = () => {
    // Implement logic for previous item swipe
  };

  const handleNextItem = () => {
    // Implement logic for next item swipe
  };

  const handleItemClick = (itemId) => {
    setActiveCompetitionTypeId(itemId.id);
    setCompetitionName(itemId.name);
  };

  return (
    <>
      <section className="competition-select">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <ul
                ref={listRef}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                style={{ overflowX: "auto", whiteSpace: "nowrap" }}
              >
                {typesData.map((type) => (
                  <li
                    key={type.id}
                    className={type.id === activeCompetitionTypeId ? "active" : ""}
                    onClick={() => handleItemClick(type)}
                    style={{ display: "inline-block" }}
                  >
                    {type.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <SelectDivision
        selectedCompetitionData={activeCompetitionTypeId}
        competitionName={competitionName}
        setLoadingState={setLoadingState}
      />
    </>
  );
}
