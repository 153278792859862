import React, { useState, useEffect } from "react";
import CompetitionSelect from "./CompetitionsSelect";
import { useSeason } from "../SeasonContext"; // Import the context

export default function SeasonsSelector({ setLoadingState }) {
  const { selectedSeason, setSelectedSeason } = useSeason(); // Use global state
  const [seasons, setSeasons] = useState([]);

  useEffect(() => {
    // Try to load the selected season from local storage
    const storedSeasonId = localStorage.getItem("selectedSeason");
    const storedSeasonName = localStorage.getItem("selectedSeasonName");
    
    if (storedSeasonId && !selectedSeason) {
      setSelectedSeason(storedSeasonId); // Set the selected season from local storage
    }

    const fetchInitialData = async () => {
      try {
        const response = await fetch(
          "https://us-central1-hsf-production-505a6.cloudfunctions.net/webApi/seasons"
        );
        const jsonData = await response.json();

        const sortedData = jsonData.sort(
          (a, b) => parseInt(b.name.split("-")[0]) - parseInt(a.name.split("-")[0])
        );
        setSeasons(sortedData.filter((season) => season.name !== "2021-22"));

        // If no season is selected, pick the first one
        if (!selectedSeason && sortedData.length > 0) {
          setSelectedSeason(sortedData[0].id);
          localStorage.setItem("selectedSeason", sortedData[0].id);
          localStorage.setItem("selectedSeasonName", sortedData[0].name);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } 
    };

    fetchInitialData();
  }, [selectedSeason, setLoadingState, setSelectedSeason]);

  // Handle changes in season
  const handleSeasonChange = (event) => {
    const newSeasonId = event.target.value;
    const newSeason = seasons.find(season => season.id === newSeasonId);
    
    if (newSeason) {
      setSelectedSeason(newSeasonId);
      localStorage.setItem("selectedSeason", newSeasonId);
      localStorage.setItem("selectedSeasonName", newSeason.name); // Save the season name
    }
  };

  return (
    <>
      <section className="year-select">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="custom-select">
                <select
                  value={selectedSeason || ""}
                  onChange={handleSeasonChange}
                >
                  {seasons.map((season) => (
                    <option key={season.id} value={season.id}>
                      {season.name}
                    </option>
                  ))}
                </select>
                <div className="select-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                  >
                    <path
                      d="M1 1L7 7L13 1"
                      stroke="#090A0A"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Render CompetitionSelect only when a season is selected */}
      {selectedSeason && (
        <CompetitionSelect seasonId={selectedSeason} setLoadingState={setLoadingState} />
      )}
    </>
  );
}
