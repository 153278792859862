import React, { useEffect, useState } from "react";

function DisplaySelectedSeason() {
  const [seasonName, setSeasonName] = useState(null);

  useEffect(() => {
    // Try to get the season name from localStorage
    const storedSeason = localStorage.getItem("selectedSeasonName");
    if (storedSeason) {
      setSeasonName(storedSeason); // Set the season name if it's stored
    }
  }, []);

  return (
    <>
      {seasonName ? seasonName : ""}
    </>
  );
}

export default DisplaySelectedSeason;
