import React, { useState, useEffect } from "react";
import FullScreenLoader from "../Components/FullScreenLoader"; // Loader component

export default function HollMatches({ selectedVenue, selectedDate, setLoadingState }) {
  const [matchesData, setMatchesData] = useState([]);
  const [loading, setLoading] = useState(true); // Internal loader state

  const venue_schedule_api_url = `https://us-central1-hsf-production-505a6.cloudfunctions.net/webApi/venues/${selectedVenue}/schedule?from=${selectedDate}&days=100`;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setLoadingState(true);

      try {
        const response = await fetch(venue_schedule_api_url);
        const data = await response.json();
        console.log(data.schedule);

        setMatchesData(data.schedule);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setTimeout(() => {
          setLoading(false);
          setLoadingState(false);
        }, 300);
      }
    };

    fetchData();
  }, [selectedVenue, selectedDate]);

  const convertDate = (seconds) => {
    const date = new Date(seconds * 1000);
    return date.toLocaleDateString("fo-FO", {
      weekday: "long",
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
  };

  let previousDate = null;

  return (
    <>
      {loading && <FullScreenLoader />} {/* Loader while fetching data */}

      <section className="venue-matches">
        <div>
          <div>
            <div>
              <table className="table">
                <tbody>
                  {matchesData.map((match) => {
                    const currentDate = convertDate(match.date._seconds);
                    const shouldCreateNewTable = currentDate !== previousDate;
                    previousDate = currentDate;

                    return (
                      <React.Fragment key={match.id}>
                        {shouldCreateNewTable && (
                          <tr className="vendate">
                            <td colSpan="7" className="venue-date">
                              {currentDate}
                            </td>
                          </tr>
                        )}

                        <tr
                          title="heinta dómaraseðil"
                          className={match.matchReport ? "hover played" : ""}
                          onClick={() => {
                            if (match.matchReport) {
                              window.open(match.matchReport, "_blank");
                            }
                          }}
                        >
                          <td className="left-align venue-clock">
                            {match.time}
                            <div className="justsmall">{match.group.name}</div>
                          </td>
                          <td className="left-align venue-league">
                            <div className="matchGroupName">{match.group.name}</div>
                            <div className="matchVenueName">
                              {match.matchReport && (
                                <img
                                  title="heinta dómaraseðil"
                                  className="pdfimg"
                                  alt=""
                                  src="/pdf.png"
                                />
                              )}
                            </div>
                          </td>
                          <td className="right-align match-team-name">{match.homeTeam.name}</td>
                          <td className="match-team-logo center-align venue-team-logo">
                            <img
                              src={`https://files.hsf.fo/clubs/${match.homeTeam.clubId}.webp`}
                              alt="Team 1 Logo"
                            />
                          </td>
                          <td className="dash">
                            {match.result?.home.goalsFor}-{match.result?.away.goalsFor}
                          </td>
                          <td className="match-team-logo center-align venue-team-logo">
                            <img
                              src={`https://files.hsf.fo/clubs/${match.awayTeam.clubId}.webp`}
                              alt="Team 2 Logo"
                            />
                          </td>
                          <td className="left-align match-team-name">{match.awayTeam.name}</td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
