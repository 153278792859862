import { Link } from "react-router-dom";
import React from "react";
import DisplaySelectedSeason from "../DisplaySelectedSeason";

export default function HeaderBar({ kappingSlag, showBack, groupName }) {
  return (
    <section className={`top-section ${showBack}`}>
      <div className="container">
        <div className="row">
          {kappingSlag && <div className="kappingSlag col-12 text-center">{kappingSlag} <DisplaySelectedSeason></DisplaySelectedSeason></div>}
          <div className="col-12 text-center">{groupName ? groupName : "DYSTIR & ÚRSLIT"}</div>
        </div>
        {showBack && (
          <Link to="/">
            <div className="backBtn">
              <img src="/back.png" />
            </div>
          </Link>
        )}
      </div>
    </section>
  );
}
