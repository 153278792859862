// SeasonContext.js
import React, { createContext, useState, useEffect, useContext } from "react";

// Create Context
const SeasonContext = createContext();

// Create a custom hook to use the context
export const useSeason = () => {
  return useContext(SeasonContext);
};

export const SeasonProvider = ({ children }) => {
  const [selectedSeason, setSelectedSeason] = useState(() => {
    // Retrieve data from local storage or use default if not available
    const storedSeason = localStorage.getItem("selectedSeason");
    return storedSeason ? JSON.parse(storedSeason) : null;
  });

  useEffect(() => {
    if (selectedSeason) {
      // Store the selected season in local storage
      localStorage.setItem("selectedSeason", JSON.stringify(selectedSeason));
    }
  }, [selectedSeason]);

  return (
    <SeasonContext.Provider value={{ selectedSeason, setSelectedSeason }}>
      {children}
    </SeasonContext.Provider>
  );
};
