import React from "react";
import ReactDOM from "react-dom/client";
import "./custom.css";
import "./css/bootstrap.css";
import Heim from "./Pages/Heim";
import DystirOgStigatalva from "./Pages/DystirOgStigatalva";
import Page404 from "./Pages/Page404";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { SeasonProvider } from "./SeasonContext"; // Import the provider
import { ErrorBoundary } from "react-error-boundary"; // Import ErrorBoundary

// Define the routes using react-router
const router = createBrowserRouter([
  { path: "/", element: <Heim />, errorElement: <Page404 /> },
  {
    path: "/dystir-og-stigatalva/:kappingSlag/:kappingId/",
    element: <DystirOgStigatalva />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    {/* Wrap the entire app with ErrorBoundary for catching errors */}
    <ErrorBoundary FallbackComponent={<Page404 />}>
      {/* Provide global season context to all components */}
      <SeasonProvider>
        <RouterProvider router={router} />
      </SeasonProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

// Start measuring performance
reportWebVitals();
