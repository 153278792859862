import React, { useState } from "react";

export default function DystarYvirlit({ scheduleData, groupName }) {
  const [showAll, setShowAll] = useState(false);
  const convertDate = (seconds) => {
    const date = new Date(seconds * 1000);
    const day = date.toLocaleDateString("fo-FO", { day: "numeric" });
    const month = date.toLocaleDateString("fo-FO", { month: "short" });
    return { day, month };
  };

  const handleShowMoreClick = () => {
    setShowAll(true);
  };

  const splitScheduleData = (scheduleData) => {
    // Get today's date in milliseconds since epoch
    const today = new Date().getTime() / 1000;

    // Initialize arrays for past matches and future matches
    const pastMatches = [];
    const futureMatches = [];

    // Iterate through each match object in scheduleData
    scheduleData.forEach((match) => {
      // Convert match date to milliseconds since epoch
      const matchDate = match.date._seconds;

      // Check if match date is before or after today
      if (matchDate < today) {
        // Match date is in the past
        pastMatches.push(match);
      } else {
        // Match date is today or in the future
        futureMatches.push(match);
      }
    });

    return { pastMatches, futureMatches };
  };

  // Usage example:
  const { pastMatches, futureMatches } = splitScheduleData(scheduleData);

  return (
    <section className="matchlist ">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {futureMatches.length > 0 && (
              <>
                <table className="table scheduleTable futureMatches">
                  <thead>
                    <tr>
                      <th colSpan={2} className="pd-med">
                        Komandi dystir
                        <span id="deildNavn" />
                      </th>
                      <th className="blue center-align" />
                      <th className="blue center-align teamlogo">Heimalið</th>
                      <th className="blue center-align" />
                      <th className="blue center-align teamlogo">Útilið</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="matchOverview">
                    {futureMatches.map(
                      (item, index) =>
                        // Check if showAll is true and index is less than 5, or if showAll is false
                        ((!showAll && index < 5) || showAll) && (
                          <tr
                            key={index}
                            title="heinta dómaraseðil"
                            className={item.matchReport ? "hover played" : ""}
                            onClick={() => {
                              if (item.matchReport) {
                                window.open(item.matchReport, "_blank");
                              }
                            }}
                          >
                            <td className="center-align date">
                              <span className="match-date-day">
                                {convertDate(item.date._seconds).day.replace(".", "")}
                              </span>
                              <span className="match-date-month">
                                {convertDate(item.date._seconds).month}
                              </span>
                            </td>
                            <td className="left-align">
                              <span className="match-number">{item.matchId}</span>
                              <span className="match-venue">{item.venue.name}</span>
                              <span className="match-clock">{item.time}</span>
                              {item.matchReport ? (
                                <img
                                  title="heinta dómaraseðil"
                                  className="pdfimg"
                                  alt=""
                                  src="/pdf.png"
                                />
                              ) : (
                                ""
                              )}
                            </td>
                            <td className="right-align match-team-name home">
                              {item.homeTeam.name}
                            </td>
                            <td className="match-team-logo left center-align">
                              <img
                                src={`https://files.hsf.fo/clubs/${item.homeTeam.clubId}.webp`} alt=''
                              />
                            </td>
                            <td className="center-align scoreline">
                              <span className="match-score">
                                {item.result?.home.goalsFor}-{item.result?.away.goalsFor}
                              </span>
                              {/*span className='match-point'>-</span*/}
                            </td>
                            <td className="match-team-logo right center-align">
                              <img
                                src={`https://files.hsf.fo/clubs/${item.awayTeam.clubId}.webp`} alt=''
                              />
                            </td>
                            <td className="left-align match-team-name away">
                              {item.awayTeam.name}
                            </td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>

                {!showAll && futureMatches.length > 5 && (
                  <div className="showMore" onClick={handleShowMoreClick}>
                    Sí fleiri
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M4 6L8 10L12 6"
                        stroke="#006EB6"
                        stroke-width="0.9"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                )}
              </>
            )}
            {pastMatches.length > 0 && (
              <table className="table scheduleTable">
                <thead>
                  <tr>
                    <th colSpan={2} className="pd-med">
                      Spældir dystir
                      <span id="deildNavn" />
                    </th>
                    <th className="blue center-align" />
                    <th className="blue center-align teamlogo">Heimalið</th>
                    <th className="blue center-align" />
                    <th className="blue center-align teamlogo">Útilið</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="matchOverview">
                  {pastMatches.reverse().map((item, index) => (
                    <tr
                      key={index}
                      title="heinta dómaraseðil"
                      className={item.matchReport ? "hover played" : ""}
                      onClick={() => {
                        if (item.matchReport) {
                          window.open(item.matchReport, "_blank");
                        }
                      }}
                    >
                      <td className="center-align date">
                        <span className="match-date-day">
                          {convertDate(item.date._seconds).day.replace(".", "")}
                        </span>
                        <span className="match-date-month">
                          {convertDate(item.date._seconds).month}
                        </span>
                      </td>
                      <td className="left-align">
                        <span className="match-number">{item.matchId}</span>
                        <span className="match-venue">{item.venue.name}</span>
                        <span className="match-clock">{item.time}</span>
                        {item.matchReport ? (
                          <img
                            title="heinta dómaraseðil"
                            className="pdfimg"
                            alt=""
                            src="/pdf.png"
                          />
                        ) : (
                          ""
                        )}
                      </td>
                      <td className="right-align match-team-name home">{item.homeTeam.name}</td>
                      <td className="match-team-logo left center-align">
                        <img src={`https://files.hsf.fo/clubs/${item.homeTeam.clubId}.webp`} alt='' />
                      </td>
                      <td className="center-align scoreline">
                        <span className="match-score">
                          {item.result?.home.goalsFor}-{item.result?.away.goalsFor}
                        </span>
                        {/*span className='match-point'>-</span*/}
                      </td>
                      <td className="match-team-logo right center-align">
                        <img src={`https://files.hsf.fo/clubs/${item.awayTeam.clubId}.webp`} alt='' />
                      </td>
                      <td className="left-align match-team-name away">{item.awayTeam.name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
